import { Calendar } from "@/components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { BentoCard, BentoGrid } from "@/registry/components/magicui/bento-grid";
import Globe from "@/registry/components/magicui/globe";
import Marquee from "@/registry/components/magicui/marquee";
import {
  CalendarIcon,
  FileTextIcon,
  GlobeIcon,
  InputIcon,
} from "@radix-ui/react-icons";

const files = [
  {
    name: "FEDIRALY BIO",
    body: "✍️ Generate your next FEDIRALY bio using AI.",
  },
  {
    name: "FEDIRALY Frame",
    body: "🎨 Generate images with beautiful gradients.",
  },
  {
    name: "FEDIRALY Spacely",
    body: "🛸 Your personalized productivity buddy",
  },
  {
    name: "FEDIRALY SVGL",
    body: "🧩 A beautiful library with SVG logos.",
  },
];

const features = [
  {
    Icon: FileTextIcon,
    name: "Features",
    description: "All addition Owned And Operated By FEDIRALY, Inc.",
    href: "https://fediraly.link/fediraly",
    cta: "Learn more",
    className: "col-span-3 lg:col-span-1",
    background: (
      <Marquee
        pauseOnHover
        className="absolute top-10 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_40%,#000_100%)] "
      >
        {files.map((f, idx) => (
          <figure
            key={idx}
            className={cn(
              "relative w-32 cursor-pointer overflow-hidden rounded-xl border p-4",
              "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
              "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
              "transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none",
            )}
          >
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-col">
                <figcaption className="text-sm font-medium dark:text-white ">
                  {f.name}
                </figcaption>
              </div>
            </div>
            <blockquote className="mt-2 text-xs">{f.body}</blockquote>
          </figure>
        ))}
      </Marquee>
    ),
  },
  
  {
    Icon: GlobeIcon,
    name: "FEDIRALY",
    description: "At FEDIRALY.NET, we offer innovative solutions and services across diverse industries. Our global platform empowers businesses and individuals to thrive in today’s dynamic market.",
    href: "/",
    cta: "Learn more",
    className: "col-span-3 lg:col-span-2",
    background: (
      <Globe className="top-0 h-[600px] w-[600px] transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_30%,#000_100%)] group-hover:scale-105 sm:left-40" />
    ),
  },
  
];

export default function BentoDemo() {
  return (
    <BentoGrid>
      {features.map((feature, idx) => (
        <BentoCard key={idx} {...feature} />
      ))}
    </BentoGrid>
  );
}
